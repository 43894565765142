import { createApp } from 'vue'
import App from './App.vue'
import  './assets/style.css';
import router from './router'
import store from './store';
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import IntersectionDirective from './frontoffice/directives/v-intersection';
import 'animate.css';


createApp(App)
.directive('intersection', IntersectionDirective)
  .use(Toast, {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
  })
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAP_API,
      libraries: 'places, directions',
    },
  })
  .use(router)
  .use(store)
  .mount('#app');
