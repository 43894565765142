export default {
    mounted(el, binding) {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger when 10% of the element is visible
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            el.classList.add(binding.value.enter || "animate__fadeIn"); // Add animation class
            observer.unobserve(el); // Stop observing after animation
          }
        });
      }, options);
  
      observer.observe(el);
    },
  };