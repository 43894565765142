import { createRouter, createWebHistory } from 'vue-router';

const FrontLayout = () => import('../frontoffice/layout/FrontLayout.vue');
const HomeView = () => import('../frontoffice/pages/HomeView.vue');
const HotelsView = () => import('../frontoffice/pages/HotelsView.vue');
const DetailsHotelsView = () => import('../frontoffice/pages/DetailsHotelsView.vue');
const DetailsRestaurantsView = () => import('../frontoffice/pages/DetailsRestaurantsView.vue');
const DetailsEventsView = () => import('../frontoffice/pages/DetailsEventsView.vue');
const DetailsMusseView = () => import('../frontoffice/pages/DetailsMusseView.vue');
const RestaurantsView = () => import('../frontoffice/pages/RestaurantsView.vue');
const EventsView = () => import('../frontoffice/pages/EventsView.vue');
const MusseView = () => import('../frontoffice/pages/MusseView.vue');
const CarView = () => import('../frontoffice/pages/CarView.vue');
const WeekendView = () => import('../frontoffice/pages/WeekendView.vue');
const ActivityView = () => import('../frontoffice/pages/ActivityView.vue');
const ActivityDetailsView = () => import('../frontoffice/pages/ActivityDetailsView.vue');
const VolsFindView = () => import('../frontoffice/pages/VolsFindView.vue');
const MaisonView = () => import('../frontoffice/pages/MaisonView.vue');
const MaisonDetailsView = () => import('../frontoffice/pages/MaisonDetailsView.vue');
const AproposView = () => import('../frontoffice/pages/AproposView.vue');
const ConditionsView = () => import('../frontoffice/pages/ConditionsView.vue');
const TermesView = () => import('../frontoffice/pages/TermesView.vue');
const MissionView = () => import('../frontoffice/pages/MissionView.vue');
const ContactView = () => import('../frontoffice/pages/ContactView.vue');
const AnnuaireHotelView = () => import('../frontoffice/pages/AnnuaireHotelView.vue');
const AnnuaireRestaurantView = () => import('../frontoffice/pages/AnnuaireRestaurantView.vue');
const ConfirmationEventView = () => import('../frontoffice/pages/ConfirmationEventView.vue');
const ReservationEventView = () => import('../frontoffice/pages/ReservationEventView.vue');
const ReservationHotelView = () => import('../frontoffice/pages/ReservationHotelView.vue');
const ReservationRestaurantView = () => import('../frontoffice/pages/ReservationRestaurantView.vue');
const AgilView = () => import('../frontoffice/pages/AgilView.vue');
const GatView = () => import('../frontoffice/pages/GatView.vue');
const ComposeCircuitView = () => import('../frontoffice/pages/ComposeCircuitView.vue');
const CircuitReservationView = () => import('../frontoffice/pages/CircuitReservationView.vue');
const SearchPageView = () => import('../frontoffice/pages/SearchPageView.vue');
const ResetPasswordView = () => import('../frontoffice/pages/ResetPasswordView.vue');
const HotelsFindView = () => import('../frontoffice/pages/HotelsFindView.vue');
const WeekendFindView = () => import('../frontoffice/pages/WeekendFindView.vue');
const HotelsFindDestinationView = () => import('../frontoffice/pages/HotelsFindDestinationView.vue');
const ActivityFindView = () => import('../frontoffice/pages/ActivityFindView.vue');
const HotelsFindCityView = () => import('../frontoffice/pages/HotelsFindCityView.vue');
const RestaurantFindCityView = () => import('../frontoffice/pages/RestaurantFindCityView.vue');

const RestaurantFindView = () => import('../frontoffice/pages/RestaurantFindView.vue');
const RestaurantFindRes = () => import('../frontoffice/pages/RestaurantFindRes.vue');
const EventFindView = () => import('../frontoffice/pages/EventFindView.vue');

const EventsFindView = () => import('../frontoffice/pages/EventsFindView.vue');
const TelecomView = () => import('../frontoffice/pages/TelecomView.vue');
const SecourView = () => import('../frontoffice/pages/SecourView.vue');
const MonumentView = () => import('../frontoffice/pages/MonumentView.vue');
const FestivalView = () => import('../frontoffice/pages/FestivalView.vue');
const MonumentDetailsView = () => import('../frontoffice/pages/MonumentDetailsView.vue');
const FestivalDetailsView = () => import('../frontoffice/pages/FestivalDetailsView.vue');
const StatesView = () => import('../frontoffice/pages/StatesView.vue');
const DestinationView = () => import('../frontoffice/pages/DestinationView.vue');
const CityView = () => import('../frontoffice/pages/CityView.vue');
const MaisonFindView = () => import('../frontoffice/pages/MaisonFindView.vue');
const UserView = () => import('../frontoffice/pages/UserView.vue');
const ConfirmationCircuitView=()=>import('../frontoffice/pages/ConfirmationCircuitView.vue');
// const TrainView = () => import('../frontoffice/pages/TrainView.vue');

const PageNotFound = () => import('../frontoffice/pages/PageNotFound.vue');

const routes = [
  {
    path: '/',
    component: FrontLayout,
    children: [
      { path: '', 
        component: HomeView,
        name:'HomeView'
       },
       { path: 'user', 
        component: UserView,
        meta: { requiresAuth: true },
       },
      { path: 'search', 
        component: SearchPageView,
        name: 'search', 
        props: (route) => ({ query: route.query.q })
      },
      { path: 'states/:slug', 
        component: StatesView,
        name: 'StatesView', 
        props: true,
      },
      // { path: 'train/:gareD/:gareA/:date', 
      //   component: TrainView,
      //   name: 'TrainView', 
      //   props: true,
      // },
      { path: 'city/:slug', 
        component: CityView,
        name: 'CityView', 
        props: true,
      },
      { path: 'destination/:slug', 
        component: DestinationView,
        name: 'DestinationView', 
        props: true,
      },
      { path: 'apropos', component: AproposView },
      { path: 'monument', component: MonumentView },
      { path: 'monument/:id', component: MonumentDetailsView,
        name: 'MonumentDetailsView',
        props: true,
       },
       { path: 'festival/:id', component: FestivalDetailsView,
        name: 'FestivalDetailsView',
        props: true,
       },
      { path: 'festival', component: FestivalView },
      { path: 'secour', component: SecourView },
      { path: 'confidentialite', component: ConditionsView },
      { path: 'confirmation', component: ConfirmationEventView },
      { path: 'circuits/confirmation', component: ConfirmationCircuitView },
      { path: 'termes', component: TermesView },
      { path: 'mission', component: MissionView },
      { path: 'hotels', component: HotelsView },
      { path: 'hotels/annuaire', component: AnnuaireHotelView },
      { path: 'restaurants', component: RestaurantsView },
      { path: 'hotels/find/:destination/:date/:endDate',
        component: HotelsFindView,
        name:'HotelsFindView',
        props: true
      },
      { path: 'hotels/findHotel/:destination',
        component: HotelsFindDestinationView,
        name:'HotelsFindDestinationView',
        props: true
      },
      { path: 'hotels/findCity/:destination',
        component: HotelsFindCityView,
        name:'HotelsFindCityView',
        props: true
      },
      { path: 'restaurants/findCity/:destination',
        component: RestaurantFindCityView,
        name:'RestaurantFindCityView',
        props: true
      },
      { path: 'weekend/findWeekend/:destination',
        component: WeekendFindView,
        name:'WeekendFindView',
        props: true
      },
      { path: 'activity/findActivity/:destination',
        component: ActivityFindView,
        name:'ActivityFindView',
        props: true
      },
      { path: 'maison/findMaison/:destination',
        component: MaisonFindView,
        name:'MaisonFindView',
        props: true
      },
      { path: 'restaurants/find/:destination/:date',
        component: RestaurantFindView,
        name:'RestaurantFindView',
        props: true
      },
      { path: 'restaurants/findRes/:destination',
        component: RestaurantFindRes,
        name:'RestaurantFindRes',
        props: true
      },
      { path: 'events/find/:destination',
        component: EventsFindView,
        name:'EventsFindView',
        props: true
      },
      { path: 'events/findEvent/:destination',
        component: EventFindView,
        name:'EventFindView',
        props: true
      },
      { path: 'restaurants/reservation', component: ReservationRestaurantView },
      { path: 'restaurants/annuaire', component: AnnuaireRestaurantView },
      { path: 'events', component: EventsView },
      { path: 'events/reservation/:id', component: ReservationEventView,
        name: 'ReservationEventView',
        props: true,
       },
      { path: 'hotels/reservation', component: ReservationHotelView },
      { path: 'maison', component: MaisonView },
      { path: 'vols/find/:departAirport/:arriveAirport', component: VolsFindView },
      { path: 'maison/:id', 
        component: MaisonDetailsView,        
        name: 'MaisonDetailsView',
        props: true, },
      { path: 'musses', component: MusseView },
      { path: 'weekend', component: WeekendView },
      { path: 'contact', component: ContactView },
      { path: 'activity', component: ActivityView },
      { path: 'activity/:id', 
        component: ActivityDetailsView,
        name: 'ActivityDetailsView',
        props: true,
      },
      { path: 'cars', component: CarView },
      { path: 'circuits/compose', component: ComposeCircuitView },
      { path: 'circuits/reservation', component: CircuitReservationView },
      { path: 'agil', component: AgilView },
      { path: 'tt', component: TelecomView },
      { path: 'gat', component: GatView },
      { path: 'reset-password/:token/:email', 
        component: ResetPasswordView,
        name: 'ResetPasswordView',
        props: true,
      },
      {
        path: 'hotels/:id',
        component: DetailsHotelsView,
        name: 'DetailsHotelsView',
        props: true,
      },
      {
        path: 'restaurants/:id',
        component: DetailsRestaurantsView,
        name: 'DetailsRestaurantsView',
        props: true,
      },
      {
        path: 'events/:id',
        component: DetailsEventsView,
        name: 'DetailsEventsView',
        props: true,
      },
      {
        path: 'musse/:id',
        component: DetailsMusseView,
        name: 'DetailsMusseView',
        props: true,
      },  
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
      },  
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token'); 

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'HomeView' }); 
  } else {
    next(); 
  }
});
export default router;
